import React from 'react'
import { Grid } from "@mui/material";
import './ResourceHubInsights.scss'
import { myStyles } from './style';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';

export default function ResourceHubInsights({title,description,className,capsulesBackgroundImage}) {
  const classes = myStyles(capsulesBackgroundImage);
  return (
    <>
    <div className={clsx('capsule-img', classes['bg-capsule'])}>
         {/* insights hub --start-   */}
         <Container maxWidth="xl" className='container-padding-none'>
          <Grid container className="insights-hub-cls">
           <Grid item xl={6} lg={6} md={8} sm={10} xs={11}>
            <Grid className="insight-hub-text">
              <h1 className="title" locId="secondaryTitle">{title}</h1>
              <div className="description" locId="secondaryTitleDescription">
               {description}
              </div>
            </Grid>
            </Grid>
          </Grid>
        </Container>
        </div>
        {/* insights hub --end-   */}
    </>
  )
}


