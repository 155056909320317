import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import ResourceHubEventsTile from './ResourceHubEventsTile.jsx';
import ResourceHubWebinarTile from './ResourceHubWebinarTile.jsx';
import './ResourceHubEventsWebinarTile.scss';
export default function ResourceHubEventWebinar({
  basePath,
  label,
  slug,
  eventsAndWebinarAllData
}) {


  return (
    <>
      <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="research-card-container">
        {eventsAndWebinarAllData.map((webinar) => (
          <>
            {webinar?.imageOrVideo ? (
              <ResourceHubEventsTile eventsWebinar={webinar} basePath={basePath} />
            ) : (
              <ResourceHubWebinarTile eventsWebinar={webinar} basePath={basePath} />
            )}
          </>
        ))}
      </Grid>
      <Grid container spacing={1} className="more-podcast-text" locid="moreEventsWebinars">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-podcasts-col">
          <Link to={`/${basePath}/` + slug}>
            <Typography className="more-podcasts-text">{label}</Typography>
          </Link>
        </Grid>
      </Grid>
      <Grid item xl={0} lg={0} md={0} sm={2} xs={0}></Grid>
    </>
  );
}
