import React from 'react';
import Grid from '@material-ui/core/Grid';
import { myStyles } from './style';
import './ResourceHubVideo.scss';
import ResourceHubVideoTile from '../ResourceHubVideoTile';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';

export default function ResourceHubVideo({ data, title, backgroundImage, basePath, label, slug }) {
  const classes = myStyles(backgroundImage);
  const videoList = data.map((video) => {
    const entryTitle = video?.entryTitle;
    const months = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];
    const dateinTimestamp = new Date(video?.videoComponent?.dateOfPublishing).getTime();
    const month = new Date(Number(dateinTimestamp)).getMonth();
    const monthString = months[month];
    const formatedDateString = monthString + ' ' + new Date(Number(dateinTimestamp)).getFullYear();
    return {
      ...video,
      entryTitle,
      publishingDate: formatedDateString,
      dateinTimestamp: dateinTimestamp,
    };
  });
  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }
  videoList.sort(comparePublishingDate);
  const videoDisplayList = videoList.slice(0, 3);
  return (
    <>
     <Container maxWidth="xl" className='padding-none'>
      <Grid container className={clsx('resourcehub-video-cls', classes.videoCls)} id={data?.entryTitle}>
      
        <Grid container className="container-padding">
          {/* **********************Title******************** */}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} locid="videosTitle">
            <h2 className="title">{title}</h2>
          </Grid>
          {/* **********************video content******************** */}
          <Grid
            container
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            locid="videoCards"
          >
            {/* video 1 tile */}
            {videoDisplayList.map((video) => (
              <ResourceHubVideoTile key={video?.videoComponent?.videoId} data={video} />
            ))}
          </Grid>
          <Grid container spacing={1} className="more-video-text" locid="moreVideos">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-video-col">
              <Link to={`/${basePath}/` + slug}>
                <Typography className="more-video-text">{label}</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      
      </Grid>
      </Container>
    </>
  );
}
