import React, { useRef, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import BlogTile from '../BlogTile';
import Carousel from 'react-elastic-carousel';
import Typography from '@material-ui/core/Typography';
import './ResourceHubBlog.scss';
import { Link } from 'gatsby';
import { urlgenerator } from '../../utils/url-generator';
import Container from '@material-ui/core/Container';
import { myStyles } from './style';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { PauseCircleFilledOutlined } from "@material-ui/icons";
export default function ResouceHubBlogs({
  blogTitle,
  data,
  basePath,
  label,
  slug,
  backgroundImg,
  autoRotateEnabled,
}) {
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 }, // works for 428
    { width: 428, itemsToShow: 1, itemsToScroll: 1 }, // works for 600
    { width: 500, itemsToShow: 2, itemsToScroll: 1 }, // works for 905
    { width: 768, itemsToShow: 3, itemsToScroll: 1 }, // works for 1240 and above
    { width: 900, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1100, itemsToShow: 4, itemsToScroll: 1 },
  ];
  const [isHovering, setIsHovering] = useState(false);
  const [sliderAnimation, setSliderAnimation] = useState(true);
  const [flag, setFlag] = useState(false);
  const carouselRef = useRef(null);
  let resetTimeout;
  const carouselProps = {
    breakPoints: breakPoints,
    pagination: true,
    autoTabIndexVisibleItems: true,
  };
  if (autoRotateEnabled && sliderAnimation) {
    carouselProps.ref = carouselRef;
    carouselProps.infiniteLoop = true;
    carouselProps.enableAutoPlay = true;
    carouselProps.autoPlaySpeed = 5000;
    carouselProps.isRTL = false;
    carouselProps.onNextEnd = ({ index }) => {
      if (data.length == index + data.length / carouselRef.current.getNumOfPages()) {
        setFlag(true);
      }
    };
  }
  else {
    carouselProps.infiniteLoop = false
  carouselProps.ref = carouselRef;
    carouselProps.enableAutoPlay = false
  }
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handlePlayBtn = () => {
    setSliderAnimation(true);
    if (flag) {
      carouselRef?.current?.goTo(0);
      setFlag(false);
    }
  };
  const handlePauseBtn = () => {
    setSliderAnimation(false);
  };
  const classes = myStyles(backgroundImg);
  const buttonEl = useRef(null);
  const registerAriaLabel = () => {
    buttonEl?.current?.querySelectorAll('.rec-dot').forEach((role) => {
      role.setAttribute('aria-label', 'pagination');
    });
  };
  useEffect(() => {
    registerAriaLabel();
    if (window) {
      window.addEventListener('resize', () => {
        registerAriaLabel();
      });
    }
  }, []);
  return (
    <Container maxWidth="xl" className="container-padding-left-none">
      <div ref={buttonEl} id={data?.entryTitle}>
        <Grid container className="blog-cls">
          <Grid item container xl={2} lg={12} md={12} sm={12} xs={12}>
            <Grid
              item
              xl={12}
              lg={12}
              md={10}
              sm={11}
              xs={12}
              className="blogsCol"
              locid="blogTitle"
            >
              <h2 className="title">{blogTitle}</h2>
            </Grid>
          </Grid>
          <Grid item container xl={10} lg={12} md={12} sm={12} xs={12} className="atCenter">
            <Grid item xl={0} lg={0} md={0} sm={0} xs={0}></Grid>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} locid="blogCards">
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={clsx('carousel-main-cont', classes['unrelenting-cls'])}
              >
                {data.length > 4 ? (
                  <>      
                {autoRotateEnabled && (
        <>
          {sliderAnimation ? (
                  <>
                    {!isHovering && (
                      <IconButton className="play-pause-icon">
                        <PauseCircleOutlineIcon
                          onMouseEnter={handleMouseOver}
                          style={{ cursor: "pointer", width: "58px", height: "58px" }}
                          
                        />
                      </IconButton>
                    )}
                    {isHovering && (
                      <IconButton className="play-pause-icon" onClick={handlePauseBtn}>
                        <PauseCircleFilledOutlined
                          style={{ cursor: "pointer", width: "58px", height: "58px" }}
                          onMouseLeave={handleMouseOut}
                          
                        />
                      </IconButton>
                    )}
                  </>
                ) : (
                  <>
                    {!isHovering && (
                      <IconButton className="play-pause-icon">
                        <PlayCircleOutlineIcon
                          onMouseEnter={handleMouseOver}
                          style={{ cursor: "pointer", width: "58px", height: "58px" }}
                          
                        />
                      </IconButton>
                    )}
                    {isHovering && (
                      <IconButton className="play-pause-icon" onClick={handlePlayBtn}>
                        <PlayCircleFilledWhiteIcon
                          style={{ cursor: "pointer", width: "58px", height: "58px" }}
                          onMouseLeave={handleMouseOut}
                          
                        />
                      </IconButton>
                    )}
                  </>
                )}
                </>
              )}
                
                    <Carousel {...carouselProps}>
                      {data.map((blog) => (
                        <BlogTile key={blog.contentful_id} blog={blog} basePath={basePath} />
                      ))}
                    </Carousel>
                  </>
                ) : (
                  <>
                    {data.map((blog) => {
                      return (
                        <Grid xl={3} lg={3} md={4} sm={6} xs={12} key={blog.contentful_id}>
                          <BlogTile blog={blog} basePath={basePath} />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Grid>
            {/* More Blogs Text */}
            <Grid container spacing={1} className="more-blog-text" locid="moreBlogs">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="more-blogs-col">
                <Link to={urlgenerator(basePath, slug)}>
                  <Typography className="more-blogs-text">{label}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
