import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Grid, Box, Typography } from '@mui/material';
import { Link } from 'gatsby';

export default function SalesCollateralTile({ sales, basePath }) {
  const richtextOptions = {
    renderText: (text) => {
      return text.split('\\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="sales-block">
        <Link
          to={
            sales?.linkOrPdf
              ? `/${basePath}/${sales?.tileLink?.slug}`
              : sales?.pdfOrDocument?.file?.url
          }
          tabindex="0"
          target={sales?.linkOrPdf ? '_self' : '_blank'}
        >
          <Box>
            <Typography className="sub-title" locid="saleTitle">
              <p className="sales-title" locid="salesTitle">
              {sales?.header?.raw &&
                documentToReactComponents(JSON.parse(sales?.header?.raw))}
              </p>
              {sales?.description?.raw && (
                <p className="sales-description" locid="salesDescription">
                  {documentToReactComponents(JSON.parse(sales?.description?.raw), richtextOptions)}
                </p>
              )}
            </Typography>
            <img
              locid="salesTile"
              src={sales?.backgroundImage?.file.url+'?fm=webp&q=100'}
              alt={sales?.backgroundImage?.description}
            />
          </Box>
        </Link>
      </Grid>
    </>
  );
}
