import React from 'react';
import './BlogTile.scss';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
export default function ShortBlog({ blog }) {
  return (
    <Grid item direction="column" xs={12} md={12} lg={12} xl={12}>
      {blog?.blogContent?.dontDisplayPublishedDateInBlogLandingTile ? (
        <></>
      ) : (
        <p locId="publishingDate" className={clsx('row', 'blog-date')}>
          {blog?.publishingDate}
        </p>
      )}

      <p
        locId="shortBlogTitle"
        component="div"
        className={clsx('row', 'blog-title')}
      >
        {blog?.blogContent?.shortBlogTitle}
      </p>
    </Grid>
  );
}
