import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@mui/material/Button';
import './ResourceHubHeader.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import clsx from 'clsx';
import { myStyles } from './style';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';
export default function ResourceHubHeader({
  primaryTitle,
  headerBlog,
  rightPrimaryTitle,
  headerResearch,
  data,
  basePath
}) {
  let blogTitle;
  let blogDescription;
  let blogImage;
  let altText;
  let researchTitle;
  let researchDescription;
  const classes = myStyles(data);
  const imageOptimum = '?fm=webp&q=100';
  if (headerBlog?.blogContent?.sys?.contentType?.sys?.id === 'shortBlog') {
    blogTitle = headerBlog?.blogContent?.shortBlogTitle;
    if (headerBlog?.blogContent?.description) {
      blogDescription = JSON.parse(headerBlog?.blogContent?.description?.raw);
    }
    blogImage = headerBlog?.blogContent?.thumbnailImage?.file?.url+imageOptimum;
    altText = headerBlog?.blogContent?.thumbnailImage?.description;
  }
  if (headerBlog?.blogContent?.sys?.contentType?.sys?.id === 'logBlog') {
    blogTitle = headerBlog?.blogContent?.longBlogTitle;
    if (headerBlog?.blogContent?.description) {
      blogDescription = JSON.parse(headerBlog?.blogContent?.description?.raw);
    }
    blogImage = headerBlog?.blogContent?.thumbnailImage.file.url+imageOptimum;
    altText = headerBlog?.blogContent?.thumbnailImage?.description;
  }
  if (headerBlog?.blogContent?.sys?.contentType?.sys?.id == 'customPodcastBlog') {
    blogTitle = headerBlog.blogContent.podcastBlogTitle;
    if (headerBlog.blogContent?.description) {
      blogDescription = JSON.parse(headerBlog.blogContent?.description?.raw);
    }
    blogImage = headerBlog.blogContent.podcastBlogTileImage.file.url+imageOptimum;
    altText = headerBlog.blogContent.podcastBlogTileImage?.description;
  }

  researchTitle = headerResearch.researchTitle;
  researchDescription = JSON.parse(headerResearch.shortDescription.raw);

  return (
    <>
    <Container maxWidth="xl" className='container-padding-none'>
      <Grid container spacing={0} className="header-cls">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            item
            className="header-container"
          >
            {/* **********************Text start******************** */}
            <Grid container xl={5} lg={5} md={12} sm={12} xs={12}>
              <Grid container xl={12} lg={12} md={9} sm={12} xs={12} className="header-grey-text">
                <Grid item xl={1} lg={0} md={2} sm={2}></Grid>
                <Grid item xl={10} lg={11} md={9} sm={9} xs={12} className="padding-box">
                  <h2 className="news" locId="primaryTitle">{primaryTitle}</h2>
                  <Link to={`/${basePath}/` + headerBlog.blogSlug} tabIndex="0">
                    <div className="sub-title" locId="shortBlogTitle">{blogTitle}</div>
                  <div className="date">{headerBlog.newsDate}</div>
                    <p className="news-description">{documentToReactComponents(blogDescription)}</p>
                  </Link>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1}></Grid>
              </Grid>
            </Grid>
            {/* **********************Text end******************** */}

            {/* **********************Image start******************** */}
            <Grid container xl={7} lg={7} md={12} sm={12} xs={12} justifyContent="flex-end" >
              <Grid
                container
                xl={12}
                lg={12}
                md={10}
                sm={10}
                xs={12}
                className={clsx("header-heroimage",classes["bg-resource-hub-hero-image"])}
              >
                <Grid item xl={5} lg={4} md={5} sm={3} xs={3}  className={clsx("header-dot-matrix",classes["bg-header-dot-matrix"])}>
                  <img
                    src={blogImage}
                    alt={altText}
                    className="hero-image-circle"
                    locId="thumbnailImage"
                  />
                </Grid>
                <Grid xl={7} lg={8} md={7} sm={9} xs={9}
                  justifyContent="flex-end"
                  className={clsx("header-blue-content",classes["bg-resource-hub-blue-content"])}
                >
                  <Grid item xl={1} lg={1} md={1} sm={1}></Grid>
                  <Grid item xl={10} lg={10} md={12} sm={10} xs={12} className="padding-box">
                    <h2 className="events">{rightPrimaryTitle}</h2>
                    <Link to={`/${basePath}/` + headerResearch.researchSlug} tabIndex="0">
                      <div className="apac">{researchTitle}</div>
                      <div className="employee">
                        {documentToReactComponents(researchDescription)}
                      </div>
                    </Link>
                    <Grid locId="buttonText" tabIndex="0" className="download-btn">
                      <Button
                        className="btn-blue"
                        disableElevation
                        href={headerResearch?.document?.file?.url}
                        download={true}
                        >
                        {data.buttonText}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xl={1} lg={2} md={1} sm={1}></Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* **********************Image start******************** */}
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  );
}
