import React from 'react';
import { Grid} from '@mui/material';
import './PodcastTile.scss';

export default function PodcastTileComponent({ podcast }) {
  return (
    <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid item xl={3} lg={4} md={2} sm={3} xs={4} locId="podcastLogo">
        <img
          src={podcast?.thumbnailImage?.file?.url+'?fm=webp&q=100'}
          alt={podcast?.thumbnailImage?.title}
          className="podcast-img"
        />
      </Grid>
      <Grid item xl={9} lg={8} md={10} sm={9} xs={8} className="podcast-text">
        <p className="episode-title" locId="podcastTitle">
          {podcast?.podcastTitle}
        </p>
        {/* <p className="duration" locId="podcastDurationInMin">
          {podcast?.podcastDurationInMin} MINS
        </p> */}
      </Grid>
      <Grid item xl={0} lg={0} md={0} sm={0} xs={0}></Grid>
    </Grid>
  );
}
