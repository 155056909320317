import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@mui/material/Select';
import { useLunr } from 'react-lunr';
import lunrData from '../../../static/resourcehub-index.json';
import ResourceHubPodcast from '../ResouceHubPodcast';
import ResouceHubBlog from '../ResourceHubBlog';
import ResourceHubResearch from '../ResourceHubResearch';
import ResourceHubInsights from '../ResourceHubInsights';
import { myStyles } from './style';
import './ResourceHubLanding.scss';
import ResourceHubHeader from '../ResourceHubHeader';
import ResourceHubVideo from '../ResourceHubVideo';
import ResourceHubSalesCollateral from '../ResourceHubSalesCollateral';
import { blogMapper, researchMapper, topicsMapper, topics } from './helper';

const ResouceHubLanding = ({
  researchData,
  podcastData,
  resourceHubData,
  videoData,
  blogData,
  basePath,
  eventsAndWebinarData,
  multiresourcesData,
  backgroundImg,
}) => {
  const classes = myStyles(resourceHubData);
  const numberOfTilesInResearch = process.env.GATSBY_NUMBER_OF_TILE_FOR_RESEARCH_IN_RESOURCEHUB;
  const numberOfTileOnPage = parseInt(process.env.GATSBY_NUMBER_OF_TILE_FOR_PODCAST_IN_RESOURCEHUB);
  const [selectedValue, setSelectedvalue] = useState('all');
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [selectedTopic, setSelectedTopic] = useState('all');
  const [searchedTerm, setSearchedTerm] = useState(null);
  const lunrResults =useLunr(`+*${searchedTerm?.toLowerCase()}*`, lunrData?.index, lunrData?.storeResourcehub)
    .filter((item) => {
      return item?.body?.toLowerCase()?.includes(searchedTerm?.toLowerCase());
    })
    .map((el) => el.id);
  const ipRef = useRef('');

  const nowTime = new Date(Date.now()).getTime();

  const blogList = blogData.map(blogMapper);

  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }

  //Blogs
  const pastBlogs = blogList.filter((blog) => blog.dateinTimestamp <= nowTime && blog?.pageVisible);
  pastBlogs.sort(comparePublishingDate);
  const headerBlog = pastBlogs.find((bl) => bl.news);
  const featuredBlogs = pastBlogs.filter((blog) => blog.featured === true);
  const NONfeaturedBlogs = pastBlogs.filter((blog) => !blog.featured);
  const blogCards = [...featuredBlogs, ...NONfeaturedBlogs];
  const resourcehubBlogs = blogCards.slice(0, 8).filter((blog) => {
    return (
      (blog.blogContent.region === selectedRegion || selectedRegion === 'all') &&
      (blog.blogContent.categoryList?.find((el) => el.categoryName === selectedTopic) ||
        selectedTopic === 'all') &&
      (lunrResults.includes(blog.contentful_id) || (!lunrResults.length && !searchedTerm))
    );
  });

  //research........
  const researchList = researchData.map(researchMapper);
  const pastResearch = researchList.filter((research) => research.dateinTimestamp <= nowTime && research?.pageVisible);
  pastResearch.sort(comparePublishingDate);
  const headerResearch = pastResearch.find((research) => research.featuredInResourceHub);

  //multiresourcesPage
  const multiResourcesList = multiresourcesData.map((resource) => {
    const dateinTimestamp = new Date(resource.dateOfPublishing).getTime();
    return { ...resource, dateinTimestamp: dateinTimestamp };
  });
  const MultiResources = multiResourcesList.filter((resource)=>resource.dateinTimestamp<=nowTime && resource?.pageVisibility);
  MultiResources.sort(comparePublishingDate);

  //podcast
  const podcastList = podcastData.map((podcast) => {
    const dateinTimestamp = new Date(podcast.publishedDate).getTime();
    return { ...podcast, dateinTimestamp: dateinTimestamp };
  });
  const pastPodcast = podcastList.filter((podcast) => podcast.dateinTimestamp <= nowTime && podcast?.pageVisible);
  pastPodcast.sort(comparePublishingDate);

  //event
  const eventsWebinarList = eventsAndWebinarData.map((item) => {
    const dateinTimestamp = new Date(item.dateOfPublishing).getTime();
    return { ...item, dateinTimestamp: dateinTimestamp };
  });
  const pastEventsWebinar = eventsWebinarList.filter((events) => events.dateinTimestamp <= nowTime && events?.pageVisible);
  pastEventsWebinar.sort(comparePublishingDate);


  //Topics added for blog

  blogCards.slice(0, 8).forEach(blog=> topicsMapper(blog.blogContent));

  pastEventsWebinar.slice(0, numberOfTileOnPage).forEach(topicsMapper);

  pastPodcast.slice(0, numberOfTileOnPage).forEach(topicsMapper);

  pastResearch.slice(0, numberOfTilesInResearch).forEach(topicsMapper);

  //Region added for all
  const regions = blogCards
    .slice(0, 8)
    .map((blog) => blog.blogContent.region)
    .concat(pastResearch.slice(0, numberOfTilesInResearch).map((research) => research.region))
    .concat(pastPodcast.slice(0, numberOfTileOnPage).map((podcast) => podcast.region))
    .concat(pastEventsWebinar.slice(0, numberOfTileOnPage).map((event) => event.region))
    .filter((item) => item);
  const uniqueRegions = [...new Set(regions)];

  //display header items
  const handelSelectChange = (e) => {
    setSelectedvalue(e.target.value);
  };

  //change of region dropdown
  const handelRegionChange = (e) => {
    setSelectedRegion(e.target.value);
  };

  //change if topics dropdown
  const handelTopicChange = (e) => {
    setSelectedTopic(e.target.value);
  };

  //handel search changes
  const handelSearch = () => {
    if(ipRef.current.value===""){
      setSearchedTerm(null)
    }else{
      setSearchedTerm(ipRef.current.value);
    }
  };
  const handelSearchOnEnter = (e) => {
    if(e.charCode===13){
      if(ipRef.current.value===""){
        setSearchedTerm(null)
      }else{
        setSearchedTerm(ipRef.current.value);
      }
    }
  };

  //filter mapper function in render
  const filterMapper=(item) => (
    <MenuItem value={item} key={item}>
      {item}
    </MenuItem>
  )
  return (
    <div className="resourcehub-main" locId="Resourcehub">
      <ResourceHubHeader
        data={resourceHubData}
        primaryTitle={resourceHubData.primaryTitle}
        rightPrimaryTitle={resourceHubData.rightPrimaryTitle}
        headerBlog={headerBlog}
        headerResearch={headerResearch}
        classes={classes}
        basePath={basePath}
      />
      <ResourceHubInsights
        title={resourceHubData?.secondaryTitle}
        description={resourceHubData?.secondaryTitleDescription}
        className={classes['bg-insight-hub-capsules']}
        capsulesBackgroundImage={resourceHubData?.capsulesBackgroundImage}
      />
      <Container maxWidth="xl" className="search-cls">
          <Grid container className="grid-container">         
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                  className="blogsCol"
                  locid="blogTitle"
                >
                  <FormControl className="select-all">
                  <Select
                    value={selectedValue}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => handelSelectChange(e)}
                  >
                    <MenuItem value="all">Select All</MenuItem>
                    <MenuItem value="blog">{resourceHubData?.blogsTitle}</MenuItem>
                    <MenuItem value="event">{resourceHubData?.eventsAndWebinarTitle}</MenuItem>
                    <MenuItem value="podcast">{resourceHubData?.podcastsTitle}</MenuItem>
                    <MenuItem value="research">{resourceHubData?.researchTitle}</MenuItem>
                  </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  className="blogsCol"
                  locid="blogTitle"
                >
                  <FormControl fullWidth>
                  <Select
                    value={selectedRegion}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => handelRegionChange(e)}
                  >
                    <MenuItem value="all">Region</MenuItem>
                    {uniqueRegions.map(filterMapper)}
                  </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  className="blogsCol"
                  locid="blogTitle"
                >
                  <FormControl fullWidth>
                  <Select
                    value={selectedTopic}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => handelTopicChange(e)}
                  >
                    <MenuItem value="all">Topics</MenuItem>
                    {topics.map(filterMapper)}
                  </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                  className="blogsCol"
                  locid="blogTitle"
                >
                <Box className="search-box">
                  <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    placeholder="Search"
                    inputRef={ipRef}
                    onKeyPress={(e)=>handelSearchOnEnter(e)}
                  />
                  </FormControl>
                  <Button tabindex="0" onClick={handelSearch} className="btn"><SearchIcon className="mui-search-icon mglass" /></Button>
                </Box>
                </Grid>
              </Grid>
         
      </Container>
      
      <div className={classes['bg-donuts']}>
        {(selectedValue === 'all' || selectedValue === 'blog') && resourcehubBlogs.length ? (
          <ResouceHubBlog
            blogTitle={resourceHubData?.blogsTitle}
            data={resourcehubBlogs}
            basePath={basePath}
            label={resourceHubData?.moreBlogs?.label}
            slug={resourceHubData?.moreBlogs?.slug}
            backgroundImg={backgroundImg}
          />
        ) : (
          <></>
        )}

        {(selectedValue !== 'all' || selectedValue !== 'podcast' || selectedValue !== 'event') && (
          <ResourceHubPodcast
            podcastTitle={resourceHubData?.podcastsTitle}
            items={pastPodcast}
            basePath={basePath}
            label={resourceHubData?.morePodcasts?.label}
            slug={resourceHubData?.morePodcasts?.slug}
            eventsAndWebinarTitle={resourceHubData?.eventsAndWebinarTitle}
            eventsAndWebinarLabel={resourceHubData?.moreEventsWebinars?.label}
            eventsAndWebinarSlug={resourceHubData?.moreEventsWebinars?.slug}
            eventsAndWebinarAllData={pastEventsWebinar}
            selectedValue={selectedValue}
            selectedRegion={selectedRegion}
            selectedTopic={selectedTopic}
            lunrResults={lunrResults}
            searchedTerm={searchedTerm}
          />
        )}

        {selectedValue === 'all' || selectedValue === 'research' ? (
          <ResourceHubResearch
            researchList={pastResearch}
            data={resourceHubData}
            multiresources={MultiResources}
            basePath={basePath}
            label={resourceHubData?.moreResearch?.label}
            slug={resourceHubData?.moreResearch?.slug}
            backgroundImage={resourceHubData?.dotMatrixBackgroundImage}
            selectedRegion={selectedRegion}
            selectedTopic={selectedTopic}
            lunrResults={lunrResults}
            searchedTerm={searchedTerm}
          />
        ) : (
          <></>
        )}
      </div>
      <ResourceHubSalesCollateral
        data={resourceHubData}
        basePath={basePath}
      ></ResourceHubSalesCollateral>
      <div className={classes['bg-donuts-right']}>
        <ResourceHubVideo
          data={videoData}
          title={resourceHubData?.videosTitle}
          backgroundImage={resourceHubData?.dotMatrixBackgroundImage}
          basePath={basePath}
          label={resourceHubData?.moreVideos?.label}
          slug={resourceHubData?.moreVideos?.slug}
        />
      </div>
    </div>
  );
};

export default ResouceHubLanding;
