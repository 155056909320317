import React, { useState,useRef,useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, Slide} from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import './ResourceHubVideoTile.scss';
import VideoPopUp from './../VideoPopUp';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ResourceHubVideoTile({ data }) {
  const [open, setOpen] = useState(false);
  const videoId = data?.videoComponent?.videoId;
  const videoType = data?.videoComponent?.videoType;
  const videoTitle = data?.videoComponent?.videoTitle;
  const qumucloudthumbnail = `https://cartus.qumucloud.com/kulu/` + videoId + `/thumbnail`;
  const youtubethumbnail = `https://img.youtube.com/vi/` + videoId + `/mqdefault.jpg`;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const buttonEl = useRef(null);
  const registerAriaLabel = ()=>{
    buttonEl?.current?.querySelectorAll("button").forEach((role)=>{
      role.setAttribute('aria-label', videoTitle);
    });
  }
  useEffect(() => {
    registerAriaLabel();
    if(window) 
    {
      window.addEventListener("resize",()=>{registerAriaLabel()})
    }
  }, []);
  return (
    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} locid="videoComponent" className="video-tile-cls" ref={buttonEl}>
      <Paper elevation={0} variant="outlined" className="video-container">
        <Button className="button-container" onClick={handleClickOpen}>
          <img
            src={videoType ? qumucloudthumbnail : youtubethumbnail}
            alt={videoTitle}
            className="video-img"
            locId = "videoType"
          />
          <div className="video-img-overlay"></div>
          <PlayCircleIcon fontSize="large" className="play-button-icon" locid="play-button" />
        </Button>
        <VideoPopUp
          videoId={videoId}
          videoType={videoType}
          open={open}
          setOpen={setOpen}
        />
        <Grid className='video-content-cls' onClick={handleClickOpen}>
          <Paper elevation={0} className='video-content'>
            <Typography className="resourcehub-video-title" locid="videoTitle">
              {videoTitle}
            </Typography>
          </Paper>
        </Grid>
      </Paper>
    </Grid>
  );
}
