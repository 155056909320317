import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({

"bg-resource-hub-hero-image":(data) =>({
  background: `url(${data.heroImage.file.url}?fm=webp&q=100) no-repeat`,
}),
"bg-resource-hub-blue-content":(data)=>({
  background: `#009ADF url(${data.headerDonutBackgroundImage.file.url}?fm=webp&q=100) no-repeat bottom right`,

}),
"bg-header-dot-matrix":(data)=>({
  '&::after':{
  background: `url(${data.dotMatrixBackgroundImage.file.url}?fm=webp&q=100) no-repeat`,
  }
})

}));
