import React,{ useEffect,useState }  from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import './BlogTile.scss';
import LongBlog from './LongBlog';
import ShortBlog from './ShortBlog';
import PodcastBlog from './PodcastBlog';
import { Link } from 'gatsby';

export default function BlogTile({ blog, basePath }) {
  const [blogData,setBlogData]=useState('')
  const [blogType,setBlogType]=useState('')
  const renderBlog = (blogtype) => {
    switch (blogtype) {
      case 'logBlog':
        return <LongBlog blog={blogData} basePath={basePath} />;
      case 'shortBlog':
        return <ShortBlog blog={blogData} basePath={basePath} />;
      case 'customPodcastBlog':
        return <PodcastBlog blog={blogData} basePath={basePath} />;
      default:
        return <></>;
    }
  };
  let blogTitle;
  let blogImage;
  const renderImage = (blogtype) => {
    switch (blogtype) {
      case 'logBlog':
      case 'shortBlog':
        blogTitle = blogData?.blogContent?.thumbnailImage?.description;
        blogImage = blogData?.blogContent?.thumbnailImage?.file?.url+'?fm=webp&q=100';
        return blogImage;
      case 'customPodcastBlog':
        blogTitle = blogData?.blogContent?.podcastBlogTileImage?.description
        blogImage = blogData.blogContent?.podcastBlogTileImage?.file?.url+'?fm=webp&q=100';
        return blogImage;
      default:
        return <></>;
    }
  };

  useEffect(()=>{
    setBlogType(blog?.blogContent?.sys?.contentType?.sys?.id)
    setBlogData({...blog})
  },[blog])
  return (
    <>
        <Grid className="cols-alignment">
        <Link to={`/${basePath}/` + blogData.blogSlug} tabindex="0">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}  >
          <Card className="root" locId="entryTitle" id={blogData.entryTitle} tabindex="-1">
            <CardActionArea tabindex="-1">
              <div className={clsx('row', 'blog-image')}>
                <CardMedia
                  component="img"
                  locId="blogImage"
                  image={renderImage(blogType)}
                  title={blogData?.blogImage?.file?.url.substring(
                    blogData?.blogImage?.file?.url.lastIndexOf('/') + 1
                  )}
                  alt={blogTitle}
                />
                {blogData.featured ? (
                  <div className="feature-txt-pos" locId="featured-blog">
                    <p className="featured-txt">Featured</p>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <CardContent className="card-content">
                <Grid container>
                  {renderBlog(blogType)}
                </Grid>
                {blogType === 'logBlog' && blogData?.blogContent?.description?.raw ? (
                  <p
                    locId="description"
                    component="p"
                    className={clsx('row', 'blog-description')}
                  >
                    {documentToReactComponents(JSON.parse(blogData?.blogContent?.description?.raw))}
                  </p>
                ) : (
                  <></>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
          </Grid>
          </Link>
          </Grid>
    </>
  );
}

