import { makeStyles } from '@material-ui/core';
export const myStyles = makeStyles((theme) => ({
  "bg-capsule": (capsulesBackgroundImage) => ({
    background: `url(${capsulesBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
  'unrelenting-cls': (data) => ({
    '& .rec-arrow-left': (data) => ({
      background: `url(${data?.leftArrowImage?.file?.url}?fm=webp&q=100) no-repeat`,
      '@media (hover: hover)':{
      '&:hover': {
       background: `url(${data?.hoverLeftArrowImage?.file.url}?fm=webp&q=100) no-repeat`,
        },
        },
        '@media (hover: none)':{
          '&:active': {
            background: `url(${data?.hoverLeftArrowImage?.file.url}?fm=webp&q=100) no-repeat`,
          },
          }
      }),
      '& .rec-arrow-right': (data) => ({
          background: `url(${data?.rightArrowImage?.file?.url}?fm=webp&q=100) no-repeat`,
          '@media (hover: hover)':{
          '&:hover': {
          background: `url(${data?.hoverRightArrowImage?.file.url}?fm=webp&q=100) no-repeat`,
        },
        },
        '@media (hover: none)':{
          '&:active': {
            background: `url(${data?.hoverRightArrowImage?.file.url}?fm=webp&q=100) no-repeat`,
          },
          }
      }),
    }),
}));