import React from 'react';
import { Grid } from '@mui/material';
import './ResourceHubSalesCollateral.scss';
import SalesCollateralTile from './SalesCollateralTile';
import Container from '@material-ui/core/Container';
export default function ResourceHubSalesCollateral({ data,basePath }) {
  return (
    
    <Grid className="sales-cls" container id="salesCollateral">
     <Container maxWidth="xl" className='padding-none'>
     <Grid container className='sales-column-reverse'>
      <Grid className="container-padding" container item xl={7} lg={7} md={12} sm={12} xs={12} spacing={2} >
        {data?.resourceHubSalesCollateral?.map((sales) => {
          return <SalesCollateralTile sales={sales} basePath={basePath}></SalesCollateralTile>;
        })}
      </Grid>
      <Grid item xl={1} lg={1} md={0} sm={0} xs={12}></Grid>
      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
        <h2 className="sales-collateral-title" locid="salesCollateralTitle">
          {data?.salesCollateralTitle}
        </h2>
      </Grid>
      </Grid>
      </Container>
    </Grid>
    
  );
}
