import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Typography from '@material-ui/core/Typography';
//import './ResearchTile.scss';

export default function ResearchTile({ research }) {
 
  const resourceType = research?.resourceType;
  let dateinTimestamp = new Date(research?.dateOfPublishing).getTime();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = new Date(Number(dateinTimestamp)).getMonth();
  const monthString = months[month];
  const formatedDateString = monthString + ' ' + new Date(Number(dateinTimestamp)).getFullYear();
  const researchTitle = research?.researchTitle;
 
  const url = research?.thumbnailImage?.file?.url+'?fm=webp&q=100';
  const shortDescription = research?.shortDescription?.raw;
  const alt = research?.thumbnailImage?.description

  return (
    <>
        <CardMedia component="img" alt={alt} locId="thumbnailImage" image={url} height="240" />
        <CardContent className="card-content">
            <Grid item direction="column">
              <Typography                
                variant="subtitle1"
                component="div"
                className="publishing-date"
                locId="dateOfPublishing"
              >
                {resourceType} / {formatedDateString}
              </Typography>

              <Typography
                variant="body2"                
                className="research-title"
                locId="researchTitle"
              >
                {researchTitle}
              </Typography>
            </Grid>
          <Typography trimText className="description" locId="shortDescription">
            {shortDescription && documentToReactComponents(JSON.parse(shortDescription))}
          </Typography>
        </CardContent>
    </>
  );
}
