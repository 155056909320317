import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles((theme) => ({
  "bg-donuts": (data) => ({
    background: `url(${data?.donutBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    backgroundSize: '60%',
    [theme.breakpoints.up('xs')]:{
      backgroundSize: '103%',
    },
    [theme.breakpoints.up('sm')]:{
      backgroundSize: '86%',
    },
    [theme.breakpoints.up('md')]:{
      backgroundSize: '88%',
    },
    [theme.breakpoints.up('lg')]:{
      backgroundSize: '80%',
    },
    [theme.breakpoints.up('xl')]:{
      backgroundSize: '60%',
    },
  }),

  "bg-donuts-right": (data) => ({
    background: `url(${data?.smallDonutBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    backgroundSize: '27%',
    backgroundPosition: 'right',
    [theme.breakpoints.up('xs')]:{
      backgroundSize: '85%',
      backgroundPosition: 'right 5%',
    },
    [theme.breakpoints.up('sm')]:{
      backgroundSize: '82%',
      backgroundPosition: 'right 10%',
    },
    [theme.breakpoints.up('md')]:{
      backgroundSize: '81%',
      backgroundPosition: 'right 14%',
    },
    [theme.breakpoints.up('lg')]:{
      backgroundSize: '82%',
      backgroundPosition: 'right 25%',
    },
    [theme.breakpoints.up('xl')]:{
      backgroundSize: '27%',
    },
  }),
  "bg-insight-hub-capsules":(data) =>({
    width:' 100%',
    background: `url(${data?.capsulesBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    backgroundPosition:' right -120px bottom 10px',
    backgroundSize: 'auto',
    minHeight: '215px',
})

}));
