import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './ResourceHubPodcast.scss';
import { urlgenerator } from '../../utils/url-generator';
import PodcastTileComponent from '../PodcastTile';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';
import ResourceHubEventWebinar from '../ResourceHubEventWebinar';

export default function ResourceHubPodcast({
  podcastTitle,
  items,
  basePath,
  label,
  slug,
  eventsAndWebinarTitle,
  eventsAndWebinarLabel,
  eventsAndWebinarSlug,
  eventsAndWebinarAllData,
  selectedRegion,
  selectedTopic,
  selectedValue,
  lunrResults,
  searchedTerm,
}) {
  const numberOfTileOnPage = parseInt(process.env.GATSBY_NUMBER_OF_TILE_FOR_PODCAST_IN_RESOURCEHUB);
  const podcastList = [...items].splice(0, numberOfTileOnPage).filter((podcast) => {
    return (
      (podcast.region === selectedRegion || selectedRegion === 'all') &&
      (podcast.categoryList?.find((el) => el.categoryName === selectedTopic) ||
        selectedTopic === 'all') &&
      (lunrResults.includes(podcast.contentful_id) || (!lunrResults.length && !searchedTerm))
    );
  });

  const eventsWebinarList = [...eventsAndWebinarAllData]
    .splice(0, numberOfTileOnPage)
    .filter((event) => {
      return (
        (event?.region === selectedRegion || selectedRegion === 'all') &&
        (event?.categoryList?.find((el) => el.categoryName === selectedTopic) ||
          selectedTopic === 'all') &&
        (lunrResults.includes(event.contentful_id) || (!lunrResults.length && !searchedTerm))
      );
    });

  return (
    <>
      <Container maxWidth="xl" className="padding-none">
        <Grid className="podcast-cls">
          <Grid container className="container-padding">
            {eventsWebinarList.length && (selectedValue === 'all' || selectedValue === 'event') ? (
              <Grid item xl={7} lg={12} md={12} sm={12} xs={12}>
                <h2 className="title" locId="eventsAndWebinarTitle">
                  {eventsAndWebinarTitle}
                </h2>
                <ResourceHubEventWebinar
                  basePath={basePath}
                  label={eventsAndWebinarLabel}
                  slug={eventsAndWebinarSlug}
                  eventsAndWebinarAllData={eventsWebinarList}
                />
              </Grid>
            ) : (
              <Grid item xl={7} lg={12} md={12} sm={12} xs={12} />
            )}
            {/* podcasts start */}
            {podcastList.length && (selectedValue === 'all' || selectedValue === 'podcast') ? (
              <Grid item xl={5} lg={12} md={12} sm={12} xs={12} className="podcast-left-padding">
                <h2 className="title" locId="podcastsTitle">
                  {podcastTitle}
                </h2>
                <Grid container className="podcast-container">
                  {podcastList.map((podcast) => (
                    <Grid
                      item
                      xl={12}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      className="episode-container"
                      key={podcast?.podcastSlug}
                    >
                      <Link
                        to={urlgenerator(basePath, podcast?.podcastSlug)}
                        className="podcast-tile"
                        locId="PodcastTileWrapper"
                      >
                        <PodcastTileComponent podcast={podcast} />
                      </Link>
                    </Grid>
                  ))}

                  <Grid container spacing={1} className="more-podcast-text" locid="morePodcasts">
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="more-podcasts-col"
                    >
                      <Link to={urlgenerator(basePath, slug)}>
                        <Typography className="more-podcasts-text">{label}</Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item></Grid>
              </Grid>
            ) : (
              <Grid item xl={5} lg={12} md={12} sm={12} xs={12} />
            )}
            {/* podcasts end */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
