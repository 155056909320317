import React from 'react';
import { Grid, CardMedia, Card, CardContent } from '@material-ui/core';
import { DateFormatUtils } from '../../utils/date-formatter';
import { Link } from 'gatsby';

export default function ResourceHubEventsTile({ eventsWebinar, basePath }) {
  const { date, month, year } = DateFormatUtils.DateMonthYearFormatter(eventsWebinar?.dateOfEvent);
  return (
    <>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="resource-space-gap events-webinar-item-col">
        <Link to={`/${basePath}/${eventsWebinar?.slug}`} tabIndex="0" className="events-webinar-card-link">
          <Card className="events-webinar-card" tabIndex="-1">
            <CardMedia
              component="img"
              alt={eventsWebinar?.eventThumbnailImage?.description}
              height="240"
              image={eventsWebinar?.eventThumbnailImage?.file?.url+'?fm=webp&q=100'}
            />
            <CardContent className="card-content">
              <Grid item container spacing={2}>
                <Grid item direction="column">
                  <div locId="eventsOrWebinarTitle" className="events-web-title">
                    {eventsWebinar?.eventsOrWebinarTitle}
                  </div>
                  <div locId="dateOfEvent" className="events-web-paragraph">
                    {date} {month} {year}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </>
  );
}
