const fullMonths = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const topics = [];

  const blogMapper=(blog) => {
    let dateinTimestamp;
    if (blog?.blogContent?.sys?.contentType?.sys?.id === 'logBlog') {
      dateinTimestamp = new Date(blog?.blogContent?.dateOfPublication).getTime();
    }
    if (blog?.blogContent?.sys?.contentType?.sys?.id === 'shortBlog') {
      dateinTimestamp = new Date(blog?.blogContent?.dateOfPublication).getTime();
    }
    if (blog?.blogContent?.sys?.contentType?.sys?.id === 'customPodcastBlog') {
      dateinTimestamp = new Date(blog?.blogContent?.dateOfPublication).getTime();
    }

    const month = new Date(Number(dateinTimestamp)).getMonth();
    const monthString = months[month];
    const fullMonthString = fullMonths[month];
    const day = new Date(Number(dateinTimestamp)).getDate();
    const formatedDateString = monthString + ' ' + new Date(Number(dateinTimestamp)).getFullYear();
    const newsDate =
      fullMonthString + ' ' + day + ', ' + new Date(Number(dateinTimestamp)).getFullYear();
    return {
      ...blog,
      publishingDate: formatedDateString,
      dateinTimestamp: dateinTimestamp,
      newsDate: newsDate,
    };
  }

  const researchMapper=(research) => {
    const dateinTimestamp = new Date(research.dateOfPublishing).getTime();
    const month = new Date(Number(dateinTimestamp)).getMonth();
    const monthString = months[month];
    const formatedDateString = monthString + ' ' + new Date(Number(dateinTimestamp)).getFullYear();
    return { ...research, publishingDate: formatedDateString, dateinTimestamp: dateinTimestamp };
  }

  const topicsMapper=(item) => {
    item.categoryList?.forEach((cat) => {
      if (!topics.includes(cat.categoryName)) topics.push(cat.categoryName);
    });
  }

  module.exports={fullMonths,months,blogMapper,researchMapper,topicsMapper,topics}


  