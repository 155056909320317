import React, { useState } from 'react';
import { Grid, Card, CardContent, Button } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import VideoPopUp from '../VideoPopUp';
import { Link } from 'gatsby';
export default function ResourceHubWebinarTile({ eventsWebinar,basePath }) {
  const videoId = eventsWebinar?.video?.videoId;
  const videoType = eventsWebinar?.video?.videoType;
  const [open, setOpen] = useState(false);
  const handleClickOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  return (
    <>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="resource-space-gap events-webinar-item-col">
        <Link to={`/${basePath}/${eventsWebinar?.slug}`} tabIndex="0" className="events-webinar-card-link">
          <Card className="events-webinar-card" tabIndex="-1">
            <CardContent className="card-content-with-video">
              <Grid item container spacing={2}>
                <Grid item direction="column">
                  <div locId="eventsOrWebinarTitle" className="events-web-title">
                    {eventsWebinar?.eventsOrWebinarTitle}
                  </div>
                  <div locId="shortSummary" className="events-web-paragraph">
                    {eventsWebinar?.shortSummary?.raw &&
                      documentToReactComponents(JSON.parse(eventsWebinar?.shortSummary?.raw))}
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      locId="buttonLabel"
                      tabindex="0"
                      className="events-web-button"
                      onClick={(e) => {
                        handleClickOpen(e);
                      }}> {eventsWebinar?.buttonLabel} </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
         </Link>
      </Grid>
      <VideoPopUp videoId={videoId} videoType={videoType} open={open} setOpen={setOpen} />
    </>
  );
}
